import React from "react";
import { Link } from "gatsby";
import { Typography } from "@material-ui/core";

type Props = {
  currentPage: number;
  path: string;
  perPage: number;
  totalCount: number;
};

const _Pagination = ({ currentPage, path, perPage, totalCount }: Props) => {
  const nav = [...Array(Math.ceil(totalCount / perPage))].map((_, i) =>
    i + 1 === 1 ? "1" : `${i + 1}`
  ); // リンク生成用の配列を作成

  return (
    <ul style={{ display: "flex", padding: 0, flexWrap: "wrap" }}>
      {nav.map((num, i) => (
        <li key={i} style={{ listStyle: "none", padding: "8px" }}>
          {currentPage === i + 1 ? (
            <Typography>{num}</Typography>
          ) : (
            <Link to={`${path}/page/${num}`}>{num}</Link>
          )}
        </li>
      ))}
    </ul>
  );
};

export default _Pagination;
