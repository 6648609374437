import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@material-ui/core";

import Layout from "../layouts/layout";
import SEO from "../components/seo";
import SayingList from "../components/sayingList";
import Pagination from "../components/pagination";
import Breadcrumbs from "@mui/material/Breadcrumbs";

export default function AffiliationPage({ data }) {
  const { mysqlAffiliation, allMysqlSaying } = data;
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const col = isLg ? 3 : isSm ? 2 : 1;
  const sayings = allMysqlSaying.nodes;

  return (
    <Layout>
      <>
        <Box style={{ padding: "4px 0", overflow: "scroll" }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">トップ</Link>
            <Typography>{`${mysqlAffiliation.name}の名言集`}</Typography>
          </Breadcrumbs>
        </Box>
        <SEO
          title={`${mysqlAffiliation.name}の名言集 |【まるっと名言集】偉人やアニメなどの名言を抜粋`}
        />
        <Typography
          component="h1"
          style={{ margin: "12px 0", fontWeight: "bold" }}
        >
          {mysqlAffiliation.name}の名言集
        </Typography>
        <SayingList sayings={sayings} col={col} />
        <Pagination
          currentPage={allMysqlSaying.pageInfo.currentPage}
          path={`/affiliations/${mysqlAffiliation.mysqlId}`}
          perPage={100}
          totalCount={allMysqlSaying.totalCount}
        />
      </>
    </Layout>
  );
}

export const query = graphql`
  query AffiliationTemplate($id: Int!, $limit: Int!, $skip: Int!) {
    mysqlAffiliation(mysqlId: { eq: $id }) {
      name
      mysqlId
    }
    allMysqlSaying(
      filter: { person: { affiliation_id: { eq: $id } } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        mysqlId
        content
        person {
          mysqlId
          name
        }
      }
      totalCount
      pageInfo {
        currentPage
      }
    }
  }
`;

export function Head({ data }) {
  const { mysqlAffiliation } = data;

  const jsonLd = `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "トップ",
        "item": "https://marutto-meogen.com"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "${mysqlAffiliation.name}の名言集",
        "item": "https://marutto-meigen.com/affiliations/${mysqlAffiliation.mysqlId}/page/1"
      }
    ]
  }`;
  return (
    <>
      <script type="application/ld+json">{jsonLd}</script>
    </>
  );
}
